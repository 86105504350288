<template>
  <div class="text-center mt-10">
    <v-progress-circular :size="100" color="#FB8C00" indeterminate :width="10" />
  </div>
</template>

<script>
export default {
  name: 'ProgressCircular'
}
</script>

<style scoped />
